import React from 'react';
import { withStyles } from "@material-ui/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import heroImage from './assets/muva-hero.jpg';

const HeroBar = ({classes:cn, heroText, heroSubText}) => {
  const mdQuery = useMediaQuery('(min-width:1000px)');
  return (
    <div className={cn.heroImageWrapper}>
      <span className={mdQuery ? cn.heroText : cn.heroTextMd}>{heroText}</span>
      {heroSubText && <span className={mdQuery ? cn.heroTextMd : cn.heroTextSm}>{heroSubText}</span>}
      <img alt="Excited For Your Move!" className={cn.heroImage} src={heroImage}/>
    </div>
  );
};

const styles = {
  heroImageWrapper: {
    position: 'relative',
    height: '322px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#5DAFDA',
    overflow: 'hidden',
    zIndex: 0,
    '@media only screen and (max-width: 600px)': {
      height: 200,
    },
  },
  heroImage: {
    zIndex: 0,
    height: '100%',
    position: 'absolute',
    top: '0',
  },
  heroText: {
    zIndex: 10,
    color: 'white',
    fontSize: '50px',
    textShadow: '2px 2px 5px rgba(0,0,0,0.25)',
    textAlign: 'center',
  },
  heroTextMd: {
    extend: 'heroText',
    margin: '0 25px',
    fontSize: '42px',
  },
  heroTextSm: {
    extend: 'heroTextMd',
    fontSize: '35px',
  }
};

export default withStyles(styles)(HeroBar);