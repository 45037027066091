import React from 'react';
import PropTypes from 'prop-types';
import {Table, TableContainer, TableBody, TableRow, TableCell as MuiTableCell} from '@material-ui/core';
import {makeStyles, withStyles} from '@material-ui/styles';

const DetailTable = ({data, valRight}) => {
  const cn = useStyles();
  console.log(data);
  return (
    <TableContainer>
      <Table size="small" aria-label="a dense table">
        <TableBody>
          {data.map(row => 
            <TableRow key={row} className={cn.line}>
              <TableCell className={cn.label}>{row[0]}</TableCell>
              <TableCell className={valRight ? cn.valRight : null}>{row[1]}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TableCell = withStyles({
  root: {
    borderBottom: "none", 
    color: 'unset',
    fontSize: 'unset',
    padding: '0 5px',
  },
})(MuiTableCell);

const useStyles = makeStyles({
  valRight: {
    textAlign: 'right',
  },
  line: {
    color: '#757575',
  },
  // labelArea: {
  //   width: '40%',
  //   '@media only screen and (max-width: 600px)': {
  //     width: '100%',
  //   },
  // },
  label: {
    fontWeight: '700',
  },
  // detailValue: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   textAlign: 'right',
  //   width: '60%',
  //   '@media only screen and (max-width: 600px)': {
  //     textAlign: 'left',
  //     width: '100%',
  //   },
  // },
});

DetailTable.propTypes = {
  data: PropTypes.array.isRequired,
};
DetailTable.defaultProps = {};

export default DetailTable;
