import React, { useState } from 'react';
import _ from 'lodash'
import moment from 'moment';
import { makeStyles } from "@material-ui/styles";
import { Paper, Button, FormHelperText, CircularProgress } from '@material-ui/core';
import { submitFeedback } from './util/firebase';
import Navbar from './Navbar';
import HeroBar from './HeroBar';
import CompletionDialog from './CompletionDialog';
import DetailLine from './DetailLine';
import Address from './Address';
import FeedbackItem from './FeedbackItem';

import {time} from './util/util';

const useStyles = makeStyles({
  body: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  panel: {
    margin: 40,
    width: 700,
    padding: 40,
    '@media only screen and (max-width: 600px)': {
      margin: 10,
      padding: 20,
    },
  },
  sectionHeader: {
    color: "#757575",
    borderBottom: '1px solid #757575',
    marginBottom: 20,
    fontSize: 25,
  },
  sectionBody: {
    marginBottom: 50,
  },
  addresses: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
    '@media only screen and (max-width: 600px)': {
      flexDirection: 'column',
    },
  },
  submitButton: {
    width: 200,
    '@media only screen and (max-width: 600px)': {
      width: '100%;,'
    },
  },
  progress: {
    marginLeft: 10,
  },
});

const Feedback = ({details}) => {
  const classes = useStyles();
  const {linkServices, services, questions} = details;
  const serviceType = linkServices[0];
  const service = services[serviceType];
  const serviceDate = time(services[serviceType]?.quote?.at);
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [err, setErr] = useState('');
  const [feedback, setFeedback] = useState(
    _.mapValues(questions, () => ({value: 0, comment: ''}))
  );

  const handleChange = type => (newVal, key) => {
    setFeedback({ 
      ...feedback, 
      [key]: {...feedback[key], [type]: newVal}
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    submitFeedback(feedback)
      .then(() => setComplete(true))
      .catch(err => setErr(`Error: ${err.message}`))
      .finally(() => setLoading(false));
  };

  const formComplete = _.reduce(feedback, (result, rating) => {
    return (rating.value === 0 || (rating.value < 5 && !rating.comment.trim())) ? false : result;
  }, true);

  return (
    <>
    <Navbar />
    <HeroBar heroText={'We Value Your Feedback'} />
    <div className={classes.body}>
      <Paper className={classes.panel} elevation={2}>
        <div className={classes.sectionHeader}>Your Move</div>
        <div className={classes.sectionBody}>
          <DetailLine label="Quote ID" values={[ details.quoteID ]}/>
          <DetailLine label="Moving Company" values={[ details.assigns[service.proID]?.proName ]} />
          <DetailLine label="Date" values={[moment(serviceDate).tz('America/Los_Angeles').format('MMMM D, YYYY')]} />
          <div className={classes.addresses}>
              <Address title="Pickup" address={details.pickup} />
              <Address title="Drop Off" address={details.destination} />
          </div>
        </div>
        <div className={classes.sectionHeader}>Feedback</div>
        {
          Object.keys(questions).map(questionKey => (
            <FeedbackItem
              key={`feedback-${questionKey}`}
              questionKey={questionKey}
              question={questions[questionKey]}
              value={feedback[questionKey].value}
              comment={feedback[questionKey].comment}
              onValueChange={handleChange('value')}
              onCommentChange={handleChange('comment')}
              finalQuestion={questionKey === 'muvaOverall' /* always displays comment box */}
            />
          ))
        }
        <Button
          className={classes.submitButton}
          variant='contained'
          size='large'
          color='primary'
          disabled={!formComplete || loading}
          endIcon={loading ? <CircularProgress size={16} className={classes.progress}/> : null}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      <FormHelperText error>{err ? err : ' '}</FormHelperText>
      </Paper>
      <CompletionDialog
        modalOpen={complete}
        message={['We have received your feedback.', 'Thank you for your time!']}
      />

    </div >
    </>
  );
}

export default Feedback;