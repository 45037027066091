import React from 'react';
import Navbar from './Navbar';
import ReceiptPanel from './ReceiptPanel';
import HeroBar from './HeroBar';

const Reservation = ({ details }) => (
  <React.Fragment>
    <Navbar />
    <HeroBar heroText={'Your Move Is Booked!'} />
    <ReceiptPanel details={details} />
  </React.Fragment>
);

export default Reservation;