import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  AppBar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import logo from "./assets/logo.png";


const useStyles = makeStyles({
  navbar: {
    display: "flex",
    alignItems: "center",
    zIndex: 100,
  },
  navbarItems: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    maxWidth: '1300px',
    padding: "5px 30px",
    '@media only screen and (max-width: 600px)': {
      padding: "5px 10px",
    },
  },
  buttonContainer: {
    paddingRight: '10px'
  },
  navbarHeader: {
    display: "flex",
    alignItems: "center",
    fontSize: "20px",
    '@media only screen and (max-width: 600px)': {
      display: 'none',
    },
  }
});

function Navbar() {
  const styles = useStyles();
  const [contactModalOpen, setContactModalOpen] = useState(false);

  return (
    <React.Fragment>
      <AppBar className={styles.navbar} position="static" color="default">
        <div className={styles.navbarItems}>
          <div>
            <img src={logo} alt="logo" height={50} />
          </div>
          <span className={styles.navbarHeader}>Customer Portal</span>
          <div className={styles.buttonContainer}>
            <Button variant="outlined" size="small" onClick={() => setContactModalOpen(true)}>
              <span>Contact Us</span>
            </Button>
          </div>
        </div>
      </AppBar>
      <Dialog open={contactModalOpen}>
        <DialogTitle>
          Contact Us
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="error-alert-description">
            If you have any questions or concerns, please email us at <a href="mailto:questions@themuva.com">questions@themuva.com</a>
            {/* or call us at <a href="tel:(775)229-2149‬">(775) 229-2149‬</a>. */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setContactModalOpen(false)}>
            Close
      </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default Navbar;
