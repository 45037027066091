import React from 'react';
import {Grid} from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";

import Navbar from './Navbar';
import InfoPanel from './InfoPanel';
import PaymentPanel from './PaymentPanel';
import HeroBar from './HeroBar';
import {QuoteState, AdminStates} from './Enums';
// import { textAlign, maxWidth } from '@material-ui/system';

function Main({ details }) {
  const cn = useStyles();
  const heroText1 
    = details.state === QuoteState.moverReview ? `Hang On!`
    : details.state === QuoteState.authFailed ? 'Hold up!'
    : `We've Got A Quote For You!`
  const heroText2
    = details.state === QuoteState.moverReview ? 'Muva is building you a new quote.'
    : details.state === QuoteState.authFailed ? 'Payment authorization failed'
    : null;
  const showPayment 
    = (details.state === AdminStates.review
    || details.state === QuoteState.authFailed);

  console.log(details);

  return <>
    <Navbar/>
    <HeroBar heroText={heroText1} heroSubText={heroText2}/>
    <Grid container>
      <Grid item xs={12} md={6} className={cn.infoCol}>
        <div className={cn.panel}>
          <InfoPanel details={details}/>
        </div>
      </Grid>
      <Grid item xs={12} md={6} className={cn.paymentCol}>
        <div className={cn.panel}>
          <PaymentPanel details={details}/>
        </div>
      </Grid>
    </Grid>
  </>;
};

const useStyles = makeStyles(theme => ({
  col: {
    display: 'flex',
    flexDirection: 'column',
    // [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    // },
  },
  infoCol: {
    extend: 'col',
    // [theme.breakpoints.up('md')]: {
    //   alignItems: 'flex-end',
    // },
    backgroundColor: '#F6F6F6',
  },
  paymentCol: {
    extend: 'col',
    paddingLeft: 20,
    paddingRight: 20,
  },
  panel: {
    maxWidth: 500,
    padding: 30,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 5,
      paddingRight: 5,
    },
  },
}));

export default Main;
