import React from 'react';
import { makeStyles } from "@material-ui/styles";


const useStyles = makeStyles({
  address: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleLine: {
    marginTop: '10px',
    fontWeight: 'bold',
    color: "#757575",
  },
  addressLine: {
    color: "#757575",
    whiteSpace: 'nowrap',
  },
});

function Address(props) {
  const
    cn = useStyles(),
    addr = props.address;

  return (
    <div className={cn.address}>
      {props.title && <span className={cn.titleLine}>{props.title}:</span>}
      <span className={cn.addressLine}>{addr.address}</span>
      {addr.apt && <span className={cn.addressLine}>Apt {addr.apt}</span>}
      <span className={cn.addressLine}>{addr.city}, {addr.state}</span>
      <span>{addr.country || ''}, {addr.zip}</span>
    </div>
  );
}

export default Address;
