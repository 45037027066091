import _ from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import React from 'react';
import { makeStyles } from "@material-ui/styles";
import {Paper, Grid} from "@material-ui/core"
import useMediaQuery from '@material-ui/core/useMediaQuery';

import DetailLine from './DetailLine';
import DetailTable from './DetailTable';
import Address from './Address';
import QuoteInfo from './QuoteInfo';
import {QuoteState} from './Enums';
import {$tr, PromoDiscount} from './util/util';

function InfoPanel({details}) {
  const 
    cn = useStyles(),
    {quotes} = details,
    d = details.details;

  const summaryMessage = [
    d.noPacking && 'self-packed',
    d.largeItems && 'some large items',
    d.specialPacking && 'some special packing',
    d.storageRequired && 'storage required',
  ].filter(v => !!v)
    .reduceRight((prev, cur, i, arr) => 
      (i === 0 || (i !== arr.length - 1))
        ? `, ${cur}${prev}`
        : `, and ${cur}${prev}`
    , '.');

  // const {promo} = details;
  // const discountAmt = !promo ? 0
  //   : promo.type === PromoDiscount.fixed ? promo.amount
  //   : promo.type === PromoDiscount.percent ? promo.amount * quote.price.max
  //   : 0;

  return <>
    <Paper className={cn.infoPanel} elevation={1}>
      <div className={cn.sectionHeader}>Request Details</div>
      <div className={cn.summaryMessage}>
        You want to move your <b>{details.moveType}</b> with <b>3</b> bedrooms<b>{summaryMessage}</b>
      </div>
      <div className={cn.addresses}>
        <Address title="Pickup" address={details.pickup} />
        <Address title="Dropoff" address={details.destination} />
      </div>
      <div className={cn.center}>
        <div>
          <DetailTable data={[["Tracking ID:", details.quoteID || details.orderID]]}/>
        </div>
      </div>

    </Paper>
    {_.map(quotes, (quote, proID) => {
      return (
        <Paper key={proID} className={cn.infoPanel} elevation={1}>
          <QuoteInfo details={details} proID={proID} />
        </Paper>
      )
    })}
  </>;
}

const useStyles = makeStyles(theme => ({
  infoPanel: {
    color: '#757575',
    lineHeight: '22px',
    marginBottom: 30,
    padding: 25,                                                                                                                                    
  },
  sectionHeader: {
    fontFamily: "'BenchNine', 'Helvetica Neue', Helvetica, Arial, Sans-Serif",
    paddingBottom: 5,
    borderBottom: '1px solid #757575',
    marginBottom:   10,
    fontSize: 25
  },
  summaryMessage: {
    marginBottom: 20,
  },
  label: {
    fontWeight: '700',
    paddingRight: 20,
  },
  addresses: {
    display: 'flex',
    justifyContent: 'space-around',
    [theme.breakpoints.down(320)]: {
      flexDirection: 'column',
    },
    marginBottom: 20,
  },
  price: {
    paddingTop: 5,
    fontWeight: '700',
    color: '#1f8acb',
    width: '100%',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    fontSize: 30,
    [theme.breakpoints.down('xs')]: {
      fontSize: 25,
    },
  },
  priceSubtext: {
    paddingTop: 5,
    fontWeight: '700',
    color: '#1f8acb',
    textAlign: 'center',
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  centerDates: {
    width: '100%',
  }
}));

export default InfoPanel;
