import React from 'react';
import { withStyles } from "@material-ui/styles";
import Rating from '@material-ui/lab/Rating';
import TextField from '@material-ui/core/TextField';
import Collapse from '@material-ui/core/Collapse';

const styles = {
  root: {
    marginBottom: 20,
    '@media only screen and (max-width: 600px)': {
      marginBottom: 10,
    },
  },
  question: {
    color: '#757575',
    marginBottom: 10,
  },
  comment: {
    display: 'block',
    marginTop: 10,
    width: '100%',
    //maxWidth: 500,
  }
}

const FeedbackItem = (
  {classes, questionKey, question, value, comment, onValueChange, onCommentChange, finalQuestion}
) => {
  const imperfect = (0 < value && value < 5)
  const showComment = imperfect || finalQuestion;
  const noComment = !(comment.trim());
  const error = finalQuestion ? imperfect && noComment : noComment;
  console.log(questionKey);
  return (
    <div className={classes.root}>
      <div className={classes.question}>{question}</div>
      <Rating 
        id={`rating-${questionKey}`}
        name={`rating-${questionKey}`}
        value={value}
        size="large"
        onChange={(event, newVal) => onValueChange(newVal, questionKey)}
      />
      <Collapse in={showComment}>
        <div className={classes.comment}>
          <TextField
            id={`comment-${questionKey}`}
            name={`comment-${questionKey}`}
            variant="outlined"
            type={'text'}
            autoComplete={'off'}
            fullWidth
            multiline={true}
            rows={finalQuestion ? 5 : 3}
            error={error}
            placeholder={finalQuestion && !imperfect ? 'Please let us know any additional thoughts ...' : 'Please explain ...'}
            helperText={error ? "Comment required" : ' ' }
            value={comment}
            onChange={event => onCommentChange(event.target.value, questionKey)}
          />
        </div>
      </Collapse>
    </div>
  )
};

export default withStyles(styles)(FeedbackItem);