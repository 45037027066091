export const AdminStates = {
  init: 'init',
  new: 'new',
  review: 'review',
  booked: 'booked',
  ongoing: 'ongoing',
  completed: 'completed',
  adminRejected: 'adminRejected',
  canceled: 'canceled',
};
export const AssignStates = {
  assigned: 'assigned',
  providerExpired: 'providerExpired',
  providerRejected: 'providerRejected',
  quoted: 'quoted',
  customerExpired: 'customerExpired',
  customerRejected: 'customerRejected',
  booked: 'booked',
  canceled: 'canceled',
};
export const ServiceStates = {
  new: 'new',
  booked: 'booked',
  canceled: 'canceled',
  reserved: 'reserved',
  reserveReview: 'reservedReview',
  authFailed: 'authFailed',
  authorized: 'authorized',
  introTerm: 'introTerm',
  recurTerm: 'recurTerm',
  awaitInvoice: 'awaitInvoice',
  invoiced: 'invoiced',
  paymentFailed: 'paymentFailed',
  addCharge: 'addCharge',
  completed: 'completed',
};