import React from 'react';
import { makeStyles } from "@material-ui/styles";
import Paper from '@material-ui/core/Paper';


const useStyles = makeStyles({
  panel: {
    display: 'flex',
    justifyContent: 'center'
  },
  panelContent: {
    paddingTop: '5px',
    paddingBottom: '20px'
  },
  root: {
    margin: '30px',
    width: '700px',
    flexDirection: 'column',
    justifyContent: 'center',
    '@media only screen and (max-width: 760px)': {
      width: 'calc(100% - 60px)'
    },
  },
  messageLine: {
    color: '#757575',
    fontSize: '20px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: '15px',
  },
  dialogMessage: {
    padding: '35px 30px'
  },
});


function ReceiptPanel(props) {
  const styles = useStyles();

  return (
    <div className={styles.panel}>
      <Paper
        className={styles.root}
        elevation={2}
      >
        <div className={styles.panelContent}>
          <span className={styles.messageLine}>We’re sorry to see you go.</span>
          <div className={styles.messageLine}>
            <span>If you have any additional questions or comments</span>
            <span>please feel free to reach out to us:</span>
          </div>
          <div className={styles.messageLine}>
            {/* <span>By Phone: (555) 555-5555</span> */}
            <a href="mailto:question@themuva.com">questions@themuva.com</a>
          </div>
          <div className={styles.messageLine}>
            <span>Have a nice day,</span>
            <span>The Muva Team</span>
          </div>
        </div>
      </Paper>
    </div >
  );
}

export default ReceiptPanel;