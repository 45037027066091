import React from 'react';
import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import logo from './assets/logo.png';

const useStyles = makeStyles({
  loadingScreen: {
    marginTop: '25px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '30px',
  },
  logo: {
    marginBottom: '30px',
  },
});


function Loading() {
  const styles = useStyles();
  return (
    <div className={styles.loadingScreen}>
      <div className={styles.loader}>
        <img src={logo} alt="logo" className={styles.logo} height={50}/>
        <CircularProgress />
      </div>
    </div>
  );
}

export default Loading;
