// TODO 2023: delete unused code from refactor

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  TextField,
  makeStyles
} from "@material-ui/core";
import {PaymentElements, clientSecretState, onStripeSubmitState} from './util/stripe';
import {createIntent, customerAccept, customerReject, getIdFromQueryString, retryAuthorize} from "./util/firebase";
import {useEffect, useState} from 'react';

import {AssignStates} from './util/status';
import CompletionDialog from "./CompletionDialog"
import PaymentTerms from './PaymentTerms';
import {QuoteState} from './Enums';
import _ from 'lodash';
import {statesObj} from './util/util';
import {useRecoilState} from 'recoil';

// TODO: notes for fixing auth broken
//  {details.state !== QuoteState.authFailed ? `Accept` 
// : tab === RETRY ? 'Retry Payment' 
// : 'Update Payment' }

// not sure what this was for:
//  const stateOptions = _.map(states, (key, val) => 
//   <MenuItem value={val} key={val}>{val}</MenuItem>
//  );

const RejectDialog = ({open, onClose}) => {
  const cn = useStyles();
  const [loading, setLoading] = useState(false);
  const onRejectConfirm = () => {
    setLoading(true);
    customerReject()
      .then(() => {
        this.props.history.push(`/cancellation?linkID=${getIdFromQueryString()}&type=rejection`);
      });
  };

  return (
    <Dialog open={open}>
      <DialogContent className={cn.dialogMessage}>
        <span>Are you sure you want to reject this quote?</span>
      </DialogContent>
      <DialogActions className={cn.dialogActions}>
        {loading ?
          <CircularProgress />
        : <>
          <Button variant="outlined" onClick={onRejectConfirm} className={cn.confirmRejectButton}>
            Confirm
          </Button>
          <Button variant="outlined" onClick={onClose}>
            Close
          </Button>
        </>}
      </DialogActions>
    </Dialog>
  );
};

const 
  RETRY = 0,
  NEW_CARD = 1,
  TEXTFIELD_VARIANT='outlined';

const PaymentForm = ({details}) => {
  const classes = useStyles();
  const isCustomWebApp = !!details.appID
  // const cstr = (props) => {
  //   // super(props);
  //   const cardInfo = props.details.cardInfo || {}

  //   this.state = {
  //     address: {
  //       line1: cardInfo.line1 || '',
  //       line2: cardInfo.line2 || '',
  //       city: cardInfo.city || '',
  //       state: cardInfo.state || '',
  //       postal_code: cardInfo.zip || '',
  //       country: 'US',
  //     },
  //     cardComplete: false,
  //     uploading: false,
  //     modalOpen: false,
  //     errorMessage: null,
  //     rejectModalOpen: false,
  //     transactionCancelled: false,
  //     tab: RETRY, // 0 = retry card, 1 = new card
  //   }
  // }

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    this.toggleUploading();

    const {state, contact} = this.props.details;
    const {tab, proID} = this.state;
    const input = {
      name: contact.name,
      address: this.state.address
    };

    const source
      = state !== QuoteState.authFailed || tab === NEW_CARD 
      ? (await this.props.stripe.createSource({
          type: 'card',
          owner: input
        })).source
      : null;

    const asyncOp 
      = state !== QuoteState.authFailed ? customerAccept(proID, source.id)
      : retryAuthorize(source && source.id);
    
    asyncOp.then(() => {
        this.toggleModalOpen()
        setTimeout(() => {
          window.location.reload();
        }, 3500);
      })
      .catch((err) => {
        this.setErrorMessage(err.message);
        this.toggleUploading();
      });
  }

  const toggleModalOpen = () => {
    this.setState({
      ...this.state,
      modalOpen: !this.state.modalOpen
    })
  }

  const toggleUploading = () => {
    this.setState({
      ...this.state,
      uploading: !this.state.uploading
    })
  }

  const handleChange = field => e => {
    const value = e.target.value;
    this.setState({
      address: {
        ...this.state.address,
        [field]: value
      }
    })
  }

  const stripeElementChange = (element, name) => {
    if (element.complete) {
      this.setState({
        cardComplete: true
      })
    } else {
      this.setState({
        cardComplete: false
      })
    }
  }

  const fillAddress = type => {
    const address = this.props.details[type];

    this.setState({
      ...this.state,
      address: {
        line1: address.address,
        line2: address.apt,
        city: address.city,
        state: address.state,
        postal_code: address.zip
      }
    });
  }

  const formComplete = () => {
    const output = (
      this.state.address.line1 !== "" &&
      this.state.address.city !== "" &&
      this.state.address.state !== "" &&
      this.state.address.postal_code !== "" &&
      this.state.cardComplete
    ) || (
      this.props.details.state === QuoteState.authFailed &&
      this.state.tab === RETRY &&
      !!this.state.proID
    );
    return output;
  }

  // const handleRejectClick = () => {
  //   this.setState({
  //     rejectModalOpen: true
  //   });
  // }

  // const handleRejectConfirm = () => {
  //   this.toggleUploading();
  //   customerReject()
  //     .then(() => {
  //       this.setState({
  //         rejectModalOpen: false,
  //         transactionCancelled: true
  //       });
  //       this.props.history.push(`/cancellation?linkID=${getIdFromQueryString()}&type=rejection`);
  //     })
  // }

  // const handleRejectCancel = () => {
  //   this.setState({
  //     rejectModalOpen: false
  //   })
  // }

  const {quotes, assigns} = details;
  const [proID, setProID] = useState('');
  const [tab, setTab] = useState();
  const [loading, setLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [elementsReady, setElementsReady] = useState(false);
  const [completeModalOpen, setCompleteModalOpen] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const updatePayment = details.state === QuoteState.authFailed;
  const oldCard = updatePayment && tab === RETRY;
  const states = statesObj();

  const [clientSecret, setClientSecret] = useRecoilState(clientSecretState);

  useEffect(() => {
    if (isCustomWebApp) {
      setProID(Object.keys(quotes)[0])
    }
  }, [])
    
  

  const onAccept = () => {
    setErrMsg('');
    setLoading(true);
    createIntent()
      // keep loading until elements are ready for display
      .then(res => setClientSecret(res.data?.client_secret))
      .catch(err => {
        setLoading(false);
        setErrMsg(err.message)
      })
  };
  const onElementsReady = () => {
    setLoading(false);
    setElementsReady(true);
  }

  const [onStripeSubmit] = useRecoilState(onStripeSubmitState);
  const onPay = () => {
    setErrMsg('');
    setLoading(true);
    onStripeSubmit()
      .then(() => customerAccept(proID))
      .then(() => {
        setCompleteModalOpen(true);
        setTimeout(() => window.location.reload(), 3500);
      })
      .catch(err => {
        console.warn({err})
        setErrMsg(err.message);
        setLoading(false);
      });
  };

  return <>
    <div className={classes.sectionHeader}>{isCustomWebApp ? 'Your Muva' : 'Select Your Muva'}</div>
    <TextField
      className={classes.providerRow}
      select
      id="proID"
      name="proID"
      value={proID}
      onChange={evt => setProID(evt.target.value)}
      variant={TEXTFIELD_VARIANT}
      type="text">
      {_.map(quotes, (quote, proID) => {
        const {state, proName} = assigns[proID];
        return <MenuItem value={proID} key={proID} disabled={state !== AssignStates.quoted}>
          {proName}
        </MenuItem>;
      })}
    </TextField>
    <PaymentTerms details={details} proID={proID}/>
    {clientSecret && (
      <div className={loading && !elementsReady ? classes.hide : undefined}>
        <div className={classes.sectionHeader}>Payment Details</div>
        <PaymentElements 
          details={details}
          moverName={assigns[proID]?.proName}
          onClearError={() => setErrMsg('')}
          onReady={onElementsReady}
          onComplete={setIsCompleted}
        />
      </div>
    )}
    <div className={classes.acceptSection}>
      {loading ? (
        <CircularProgress />
      ) : (
        <Button
          className={classes.acceptButton}
          color="secondary"
          variant="contained"
          disabled={!proID || loading || (clientSecret ? !isCompleted : false)}
          onClick={clientSecret ? onPay : onAccept}>
          {clientSecret ? 'Book Now' : 'Accept'}
        </Button>
      )}
      {errMsg &&
        <span className={classes.errorMessage}>{errMsg}</span>
      }
    </div>
    {!clientSecret && (
      <div className={classes.rejectSection}>
        <Button
          variant="text"
          size="small"
          className={classes.rejectButton}
          onClick={() => setRejectModalOpen(true)}>
          I Do Not Accept
        </Button>
      </div>
    )}
    <CompletionDialog
      modalOpen={completeModalOpen}
      message={['Your transaction has been processed.']}
    />
    <RejectDialog open={rejectModalOpen} onClose={() => setRejectModalOpen(false)}/>
  </>;
}

const useStyles = makeStyles({
  hidden: {
    opacity: 0,
  },
  sectionHeader: {
    color: "#757575",
    paddingBottom: 5,
    borderBottom: '1px solid #757575',
    marginBottom: 15,
    fontSize: 25,
  },
  tabs: {
    marginTop: 10,
    marginBottom: 15,
    border: '1px solid #757575',
  },
  tab: {
    fontSize: 17,
  },
  hide: {
    display: 'none'
  },
  providerRow: {
    width: '100%',
    marginBottom: 20,
  },
  acceptSection: {
    minHeight: 50,
    marginTop: 25,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  acceptButton: {
    //color: 'white',
    fontWeight: '600',
    width: '225px',
    height: '45px',
    borderRadius: '5px',
    fontSize: '18px',
  },
  errorMessage: {
    color: 'red',
    marginTop: '15px'
  },
  rejectSection: {
    display: 'flex',
    justifyContent: 'center'
  },
  rejectButton: {
    marginTop: '30px',
    width: '155px',
    fontWeight: '400',
    fontSize: '10px'
  },
  confirmRejectButton: {
    borderColor: 'red',
    color: 'red',
  },
  dialogMessage: {
    padding: '35px 30px'
  },
  dialogActions: {
    padding: '12px'
  }
});

export default PaymentForm;
