import _ from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import React from 'react';
import {makeStyles} from "@material-ui/styles";
import {Grid} from "@material-ui/core"
import {pretty, time, dateStr, dateTimeStr} from './util/util'

import DetailTable from 'DetailTable';
import {AssignStates} from 'Enums';
 
const QuoteInfo = ({details, proID}) => {
  const isCustomWebApp = !!details.appID
  const quote 
    = details.state === 'review' ? details.quotes[proID]
    : {
      move: details.services.move?.quote || {},
      storage: details.services.storage?.quote || {},
      moveIn: details.services.moveIn?.quote || {},
    };
  const summary = details.quotesSummary[proID];
  const assign = details.assigns[proID];
  const {proName, state} = assign;
  const {at: moveAt, notes: moveNotes} = quote['move'] || {};
  const {at: moveInAt, notes: moveInNotes} = quote['moveIn'] || {};
  const {at: storageAt, endAt: storageEndAt , notes: storageNotes} = quote['storage'] || {};
  const cn = useStyles({ready: state === AssignStates.quoted || state === AssignStates.booked});
  const stateMsg
    = (assign.state === AssignStates.customerExpired 
      || assign.state === AssignStates.providerExpired
      || assign.state === AssignStates.providerRejected) 
      ? 'Price Guarantee expired, please contact us.'
    : assign.state === AssignStates.assigned ? 'Building you a new quote ...'
    : assign.state === AssignStates.quoted
      ? `Price Guarantee until: ${time(assign.expiresAt).format('MMM D, YYYY')}`
    : null;
  
  return (
    <Grid container spacing={3} className={cn.quoteInfo}>
      <Grid item xs={12}>
        <div className={cn.sectionHeader}>{proName}</div>
      </Grid>
      <Grid item container xs={12} className={cn.body}>
        <Grid item xs={12}>
          <div className={cn.price}>{isCustomWebApp ? `$ ${summary.totalMax / 100}` : summary.totalPriceStr}</div>
          {summary.type === 'moveStorage' &&
            <div className={cn.priceSubtext}>*Includes Storage</div>
          }
        </Grid>
        <Grid className={cn.center} item xs={12}>
          <div styles={cn.centerDates}>
            <DetailTable 
              data={[
                  moveAt ? [(moveInAt ? 'Move Out:' : 'Move Date:'), dateTimeStr(moveAt)]
                : storageAt ? ['Storage Start:', dateStr(storageAt)]
                : [],
                  moveInAt ? ['Move In:', dateStr(moveInAt)] 
                : storageEndAt ? ['Storage End:', dateStr(storageEndAt)]
                : [],
              ]}
            />
          </div>
        </Grid>
        {/* <DetailLine label="Estimated Minimum" values={[$tr(price.min)]}/>
        <DetailLine label="Guaranteed Maximum" values={[$tr(price.max)]}/> */}
        {/* <DetailLine label="Estimated Savings Using Muva" values={[$tr(price.max/10 + discountAmt)]}/> */}
        {state === 'reviews' &&
          <Grid item xs={12}>
            <div className={cn.finePrint}>{moveNotes}</div>
            <div className={cn.finePrint}>{storageNotes}</div>
            <div className={cn.finePrint}>{moveInNotes}</div>
          </Grid>
        }
      </Grid>
      {stateMsg && <Grid item xs={12} className={cn.status}>{stateMsg}</Grid>}
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  quoteInfo: {
    color: '#757575',
  },
  body: ({ready}) => ({
    opacity: ready ? 1 : 0.5,
  }),
  sectionHeader: {
    color: "#757575",
    borderBottom: '1px solid #757575',
    fontSize: '25px'
  },
  label: {
    fontWeight: '700',
    paddingRight: 20,
  },
  price: {
    paddingTop: 5,
    fontWeight: '700',
    color: '#1f8acb',
    width: '100%',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    fontSize: 30,
    [theme.breakpoints.down('xs')]: {
      fontSize: 25,
    },
  },
  priceSubtext: {
    paddingTop: 5,
    fontWeight: '700',
    color: '#1f8acb',
    textAlign: 'center',
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 5,
    marginBottom:   10,
  },
  centerDates: {
    width: '100%',
  },
  finePrint: {
    color: '#757575',
    fontSize: '0.85rem',
    lineHeight: '1rem',
  },
  status: {
    textAlign: 'center',
  }
}));

export default QuoteInfo;
