import {AddressElement, Elements, PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {atom, useRecoilState, useRecoilValue} from 'recoil';

import {loadStripe} from '@stripe/stripe-js';
import {makeStyles} from '@material-ui/styles';
import {useEffect} from 'react';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// export const stripePromise = loadStripe(
//   process.env.NODE_ENV === 'production' 
//     ? process.env.REACT_APP_STRIPE_API_KEY
//     : process.env.REACT_APP_STRIPE_API_KEY_TEST
// );

export const clientSecretState = atom({key: 'clientSecret', default: ''});
export const onStripeSubmitState = atom({key: 'onStripeSubmitState', default: () => {}});

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  }
});

const adaptAddr = (addr) => ({
  line1: addr.address,
  city: addr.city,
  state: addr.state,
  postal_code: addr.zip,
  country: addr.country,
}); 

const SubmitExport = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [_,setOnStripeSubmit] = useRecoilState(onStripeSubmitState);

  useEffect(() => {
    const onStripeSubmit = async () => {
      console.log('calling submit');

      const {error} = await stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          // TODO 2023: fix this return url
          return_url: 'https://example.com/account/payments/setup-complete',
        },
        redirect: 'if_required',
      });
      if (error)
        throw new Error(error.message);
    };
    setOnStripeSubmit(() => onStripeSubmit);
  }, [stripe, elements, setOnStripeSubmit])
  return null;
};

const loadPromiseRef = {
  current: loadStripe(
    process.env.NODE_ENV === 'production' 
      ? process.env.REACT_APP_STRIPE_API_KEY
      : process.env.REACT_APP_STRIPE_API_KEY_TEST
  ),
}

export const PaymentElements = ({details, moverName, onReady, onClearError, onComplete}) => {
  // const loadPromiseRef = useRef(loadStripe(
  //   process.env.NODE_ENV === 'production' 
  //     ? process.env.REACT_APP_STRIPE_API_KEY
  //     : process.env.REACT_APP_STRIPE_API_KEY_TEST
  // ));
  const cn = useStyles();
  const clientSecret = useRecoilValue(clientSecretState);

  const {appID} = details;
  const hasAppId = !!appID;
  
  return (
    <Elements
      stripe={loadPromiseRef.current}
      options={{clientSecret}}>
      <div className={cn.root}>
        <AddressElement 
          onReady={onReady}
          options={{
            mode: 'billing',
            allowedCountries: ['US'],
            contacts: [{
              name: details.contact.name,
              address: adaptAddr(details.pickup),
            },{
              name: details.contact.name,
              address: adaptAddr(details.destination),
            }]
          }} />
        <PaymentElement
          onFocus={onClearError}
          onChange={(e) => {
            const { complete } = e;
            if (typeof onComplete === 'function') onComplete(complete);
          }}
          options={{
            defaultValues: {
              billingDetails: {
                email: details.contact.email,
                name: details.contact.name,
              }
            },
            business: {name: moverName},
            paymentMethodOrder: hasAppId ? ['us_bank_account', 'card'] : ['card']
          }}
        />
        <SubmitExport />
      </div>
    </Elements>
  );
};

