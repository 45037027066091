import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

function DetailLine({ label, subLabel, values, longValue, shortVal }) {
  const styles = useStyles();
  return (
    <Grid container className={styles.line}>
      <Grid item xs={6}>
        <span className={styles.label}>{label}</span>
      </Grid>
      <Grid item xs={6}>
        {values[0]}
        {/* {values.map(str => <span>{str}</span>) } */}
      </Grid>
      {subLabel && 
        <Grid item xs={12} sm={longValue ? 12 : 6}>
          <span>{subLabel}</span>
        </Grid>
      }
    </Grid>
  );
};

const useStyles = makeStyles({
  line: {
    color: '#757575',
  },
  // labelArea: {
  //   width: '40%',
  //   '@media only screen and (max-width: 600px)': {
  //     width: '100%',
  //   },
  // },
  label: {
    fontWeight: '700',
  },
  // detailValue: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   textAlign: 'right',
  //   width: '60%',
  //   '@media only screen and (max-width: 600px)': {
  //     textAlign: 'left',
  //     width: '100%',
  //   },
  // },
});

DetailLine.propTypes = {
  label: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
};
DetailLine.defaultProps = {};

export default DetailLine;
