import * as moment from 'moment';
import 'moment-timezone';

export function statesObj() {
  return {
    "AL": "Alabama",
    "AK": "Alaska",
    "AS": "American Samoa",
    "AZ": "Arizona",
    "AR": "Arkansas",
    "CA": "California",
    "CO": "Colorado",
    "CT": "Connecticut",
    "DE": "Delaware",
    "DC": "District Of Columbia",
    "FM": "Federated States Of Micronesia",
    "FL": "Florida",
    "GA": "Georgia",
    "GU": "Guam",
    "HI": "Hawaii",
    "ID": "Idaho",
    "IL": "Illinois",
    "IN": "Indiana",
    "IA": "Iowa",
    "KS": "Kansas",
    "KY": "Kentucky",
    "LA": "Louisiana",
    "ME": "Maine",
    "MH": "Marshall Islands",
    "MD": "Maryland",
    "MA": "Massachusetts",
    "MI": "Michigan",
    "MN": "Minnesota",
    "MS": "Mississippi",
    "MO": "Missouri",
    "MT": "Montana",
    "NE": "Nebraska",
    "NV": "Nevada",
    "NH": "New Hampshire",
    "NJ": "New Jersey",
    "NM": "New Mexico",
    "NY": "New York",
    "NC": "North Carolina",
    "ND": "North Dakota",
    "MP": "Northern Mariana Islands",
    "OH": "Ohio",
    "OK": "Oklahoma",
    "OR": "Oregon",
    "PW": "Palau",
    "PA": "Pennsylvania",
    "PR": "Puerto Rico",
    "RI": "Rhode Island",
    "SC": "South Carolina",
    "SD": "South Dakota",
    "TN": "Tennessee",
    "TX": "Texas",
    "UT": "Utah",
    "VT": "Vermont",
    "VI": "Virgin Islands",
    "VA": "Virginia",
    "WA": "Washington",
    "WV": "West Virginia",
    "WI": "Wisconsin",
    "WY": "Wyoming"
  };
}

export const PromoDiscount = {
  fixed: "FIXED",      // fixed discount code
  percent: "PERCENT",    // percentage discount code
  none: "NONE",       // no discount, just referral code
};

export const $tr = (cents) => {
  return (cents % 100) === 0 
    ? `$${cents/100}`
    : `$${(cents/100).toFixed(2)}`;
};

export const maxDiscount = (quote, promo) => {
  const maxPrice = quote.price.max/100;
  return !promo ? 0
    : promo.type === PromoDiscount.fixed ? promo.amount
    : promo.type === PromoDiscount.percent ? Math.floor(maxPrice * promo.amount/100)*100
    : 0;
};

export const maxPayment = (quote, promo) => {
  const maxPrice = quote.price.max;
  return maxPrice - quote.deposit - maxDiscount(quote, promo);
};

export const time = 
  (input = Date.now(), timezone ='America/Los_Angeles') => {  
  // input normalization for Moment
  //const arg = input instanceof Timestamp ? input.toMillis() : input;
  // convert to moment
  return moment.tz(input, timezone);
};

export const dateStr
  = t => time(t).tz('America/Los_Angeles').format("MMM D, YYYY");
export const timeStr
  = t => time(t).tz('America/Los_Angeles').format('h:mm a')
export const dateTimeStr 
  = t => time(t).tz('America/Los_Angeles').format("MMM D, YYYY, h:mm a");
export const shortDateStr
  = t => time(t).tz('America/Los_Angeles').format("MM/D/YY");
  // time(t).format("h:mm A z - MMMM Do YYYY");

/** creates consistent hashcode from a string */
export const hashCode = (str) => {
  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
    let char = str.charCodeAt(i);
    hash = ((hash<<5)-hash)+char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};

