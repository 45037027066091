import React from 'react';
import 'moment-timezone';
import {makeStyles} from "@material-ui/styles";
import {QuoteState} from './Enums';

import PaymentForm from './PaymentForm';

// TODO: fix auth failure
function PaymentPanel({ details }) {
  const styles = useStyles();
  const isCustomWebApp = !!details.appID;
  const payTitle = isCustomWebApp ? 'Pay' : 'Select & Pay'
  const subtitle = isCustomWebApp ?
    'Click on the Accept button to book this move' :
    'Thank you for choosing Muva! If you\'d like to book your move through us, please select your desired Muva and fill out your payment information below:'

  const title
    = details.state === QuoteState.authFailed ? 'Retry Authorization' : payTitle;

  return <>
    <div className={styles.panelTitle}>{title}</div>
    <div className={styles.panelText}>
      {details.state !== QuoteState.authFailed ? <>
        {subtitle}
      </> : <>
        Thank you for booking with Muva! <br/>
        <br/>
        Unfortunately, the payment authorization
        failed to pass for your payment method. <br/>
        <br/>
        In order to keep this booking, you must submit a payment method that
        passes authorization before the cancellation deadline <b>{/*expireStr*/}</b>, or your
        move will be automatically canceled.<br/>
        {/* <br/>
        This authorization must complete for
        the maximum possible payment {maxFinalPayment}. Any funds in excess of the
        final price will be refunded at time of billing, 1-2 days after move is complete. */}
      </>}
    </div>
    <PaymentForm details={details} />
  </>;
};

const useStyles = makeStyles({
  panelHeader: {
    width: '100%',
    marginBottom: '40px',
    textAlign: 'center'
  },
  panelTitle: {
    color: '#1f8acb',
    fontSize: '40px',
    marginBottom: 10,
    textAlign: 'center',
  },
  panelText: {
    color: '#757575',
    marginBottom: 10,
  },
  sectionHeader: {
    borderBottom: '1px solid',
    marginBottom: '20px'
  },
});

export default PaymentPanel;
