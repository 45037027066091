
export const QuoteState = {
  init                : 'INIT',             // after initialized, before videos uploaded and submitted
  new                 : 'NEW',              // newly submitted
  seen                : 'SEEN',             // not actually a quote state, just a marker to see a quote
  adminRejected       : 'ADMIN_REJECTED',   // rejected by admin (final state)
  moverReview         : 'MOVER_REVIEW',     // in review for quote generation by mover
  moverRejected       : 'MOVER_REJECTED',   // rejected by mover
  moverExpired        : 'MOVER_EXPIRED',    // mover did not respond by endOfTommorrow()
  customerReview      : 'CUSTOMER_REVIEW',     // in review by customer
  customerRejected    : 'CUSTOMER_REJECTED',   // quote rejected by customer (final state)
  customerExpired     : 'CUSTOMER_EXPIRED',    // customer did not respond to quote in time (final state?)
  booked              : 'BOOKED',              // quote accepted by customer, move will happen
  canceled            : 'CANCELED',            // canceled after move booked (final state)
  authFailed          : 'AUTHORIZE_FAILED',    // failure to place a payment hold for the full amount on the account
  authorized          : 'AUTHORIZED',          // booked and authorized
  needsInvoice        : 'NEEDS_INVOICE',       // waiting on mover to submit invoice
  invoiced            : 'INVOICED',            // mover submitted invoice
  paymentFailed       : 'PAYMENT_FAILED',      // payment failed with stripe
  addChargePayFailed  : 'ADDITIONAL_CHARGE_AND_PAYMENT_FAILURE', // stripe payment failed and additional charges exist
  addCharge           : 'ADDITIONAL_CHARGE',   // stripe complete, additional charges present
  completed           : 'COMPLETED',            // payment completed (final state)
};

export const AdminStates = {
  init: 'init',
  new: 'new',
  review: 'review',
  booked: 'booked',
  ongoing: 'ongoing',
  completed: 'completed',
  adminRejected: 'adminRejected',
  canceled: 'canceled',
};
export const AssignStates = {
  assigned: 'assigned',
  providerExpired: 'providerExpired',
  providerRejected: 'providerRejected',
  quoted: 'quoted',
  customerExpired: 'customerExpired',
  customerRejected: 'customerRejected',
  booked: 'booked',
  canceled: 'canceled',
};
export const ServiceStates = {
  new: 'new',
  booked: 'booked',
  canceled: 'canceled',
  reserved: 'reserved',
  reserveReview: 'reservedReview',
  authFailed: 'authFailed',
  authorized: 'authorized',
  introTerm: 'introTerm',
  recurTerm: 'recurTerm',
  awaitInvoice: 'awaitInvoice',
  invoiced: 'invoiced',
  paymentFailed: 'paymentFailed',
  addCharge: 'addCharge',
  completed: 'completed',
};

export const LinkTypes = {
  providerReview: 'providerReview',
  provider: 'provider',
  customerReview: 'customerReview',
  customer: 'customer',
  feedback: 'feedback'
};
