import React, { useEffect, useState } from 'react';
import {RecoilRoot} from 'recoil';
import { create } from 'jss';
import jssExtend from 'jss-plugin-extend';
import { makeStyles, StylesProvider, jssPreset, ThemeProvider } from "@material-ui/styles";
import { getInvoiceInfo } from './util/firebase';
import {AdminStates, LinkTypes} from './Enums';
import theme from './theme';
import './App.css';

import Main from './Main';
import Loading from "./Loading";
import CompletionDialog from "./CompletionDialog"
import Reservation from "./Reservation";
import Cancellation from "./Cancellation";
import Feedback from "./Feedback";

const jss = create({ plugins: [jssExtend(), ...jssPreset().plugins] });

function App() {
  const
    styles = useStyles(),
    [loading, setLoading] = useState(true),
    [details, setDetails] = useState({}),
    {linkType, state} = details,
    [expired, setExpired] = useState(false),
    [message, setMessage] = useState([
      'This link is no longer active.',
      'We apologize for the inconvenience.',
    ]);

  useEffect(() => {
    setLoading(true);
    getInvoiceInfo()
      .then(res => {
        if (res) {
          console.log(res);
          setDetails(res);
        } else
          setExpired(true);
      })
      .catch(e => {
        setExpired(true);
        setMessage([
          'Uh oh! Looks like something went wrong.',
          'Either this link is no longer active, or there was an error.',
          'We apologize for the inconvenience',
        ]);
      })
      .finally(() => setLoading(false))
  }, []);

  return (
    <RecoilRoot>
    <StylesProvider jss={jss}>
    <ThemeProvider theme={theme}>
      <div className={styles.appContainer}>
        { loading ? <Loading />
          : state === AdminStates.canceled ? 
            <Cancellation details={details} />
          : state === AdminStates.review ?
            <Main details={details} />
          : linkType === LinkTypes.customer ?
            <Reservation details={details} />
          : linkType === LinkTypes.feedback ?
            <Feedback details={details} />
          : null
        }
        <CompletionDialog
          modalOpen={expired}
          message={message}
        />
      </div>
    </ThemeProvider>
    </StylesProvider>
    </RecoilRoot>
  );
};

const useStyles = makeStyles({
  appContainer: {
    height: "100%"
  }
});

export default App;
