import React from 'react';
import Navbar from './Navbar';
import CancelInfoPanel from './CancelInfoPanel';

const Cancellation = () => (
  <React.Fragment>
    <Navbar />
    <CancelInfoPanel />
  </React.Fragment>
);

export default Cancellation;