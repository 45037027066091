import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import logo from './assets/logo.png';

const useStyles = makeStyles({
  completionDialog: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '50px',
  },
  messageArea: {
    whiteSpace: 'pre-wrap',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: '20px',
    color: '#757575',
    fontWeight: '600',
    fontSize: '20px',
  },
  messageLine: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  homeButton: {
    width: '240px',
    boxShadow: '0px 4px 10px -2px rgba(0,0,0,0.75)',
    marginBottom: '30px',
  },
});

function CompletionDialog({ modalOpen, message }) {
  const styles = useStyles();
  return (
    <Dialog open={modalOpen} fullScreen>
      <div className={styles.completionDialog}>
        <div className={styles.messageArea}>
          {
            message.map(line => (
              <span className={styles.messageLine} key={line}>
                {line}
              </span>
            ))
          }
        </div>
        <img src={logo} alt="logo" height={50} />
      </div>
    </Dialog>
  );
}

CompletionDialog.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  message: PropTypes.any.isRequired,
};

CompletionDialog.defaultProps = {};

export default CompletionDialog;
