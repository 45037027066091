import queryString from 'query-string';
import firebase from "firebase/compat/app";
import "firebase/compat/functions";

console.log(process.env);

const devConfig = {
  apiKey: 'AIzaSyAx-i3luVOn2d4iIFc1R9hCvC6XVWIaP08',
  authDomain: 'muva-backend-dev.firebaseapp.com',
  databaseURL: 'https://muva-backend-dev.firebaseio.com',
  projectId: 'muva-backend-dev',
  storageBucket: 'muva-backend-dev.appspot.com',
  messagingSenderId: '535024031644',
  appId: '1:535024031644:web:a444864250dd26bb',
};

const firebaseConfig = {
  apiKey: 'AIzaSyAqLMsS5QKyaavYDRjqnHwbOWde4M8yZyo',
  authDomain: 'muva-backend.firebaseapp.com',
  databaseURL: 'https://muva-backend.firebaseio.com',
  projectId: 'muva-backend',
  storageBucket: 'muva-backend.appspot.com',
  messagingSenderId: '349722643142',
  appId: '1:349722643142:web:8ea00877c238394c5ba5c7',
};

if (process.env.NODE_ENV === 'development') {
  if (process.env.REACT_APP_STAGE) firebase.initializeApp(firebaseConfig);
  else firebase.initializeApp(devConfig);
} else {
  firebase.initializeApp(firebaseConfig);
}

const getFromURL = (id) => {
  if (!(typeof window !== 'undefined' && window && window.location)) return null;
  const {location} = window;
  const parsed = queryString.parse(location.search);
  return parsed[id];
};
export const getIdFromQueryString = () => getFromURL('linkID');
export const getTypeFromQueryString = () => getFromURL('type');

const functions = firebase.functions();

// connect to local emulator env
if (process.env.REACT_APP_EMULATE) {
  const devHost = getFromURL('devHost');
  console.log(devHost);
  functions.useEmulator(devHost || 'localhost', 5001);
}

const
  getLinkFn = functions.httpsCallable('order-getLink'),
  createIntentFn = functions.httpsCallable('order-createIntent'),
  customerAcceptFn = functions.httpsCallable('order-customerAccept'),
  customerRejectFn = functions.httpsCallable('order-customerReject'),
  cancelMoveFn = functions.httpsCallable('order-cancel'),
  feedbackFn = functions.httpsCallable('order-sendFeedback'),
  requestMoveInFn = functions.httpsCallable('order-requestMoveIn'),
  authorizeFn = functions.httpsCallable('order-retryAuthorize');

export const getInvoiceInfo = async () => {
  const linkID = getIdFromQueryString();
  const response = await getLinkFn({ linkID });
  return {
    ...response.data,
    linkID,
  };
};

export const customerAccept = (proID) => {
  const linkID = getIdFromQueryString();
  return customerAcceptFn({ linkID, proID });
};

export const createIntent = () => {
  const linkID = getIdFromQueryString();
  return createIntentFn({ linkID });
};

export const customerReject = () => {
  const linkID = getIdFromQueryString();
  return customerRejectFn({ linkID });
};

export const cancelMove = () => {
  const linkID = getIdFromQueryString();
  return cancelMoveFn({ linkID });
};

export const submitFeedback = (feedback) => {
  const linkID = getIdFromQueryString();
  return feedbackFn({ linkID, feedback });
};

export const retryAuthorize = (sourceID) => {
  const linkID = getIdFromQueryString();
  return authorizeFn({ linkID, ...sourceID && {sourceID} });
};

export const requestMoveIn = (endAt) => {
  const linkID = getIdFromQueryString();
  return requestMoveInFn({linkID, endAt})
};