import _ from 'lodash';
import React, { useState } from 'react';
import { makeStyles } from "@material-ui/styles";
import {
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  TextField
} from "@material-ui/core";
import moment from 'moment-timezone';

import DetailTable from './DetailTable';
import Address from './Address';
import QuoteInfo from './QuoteInfo';
import {cancelMove, requestMoveIn} from './util/firebase';
import {AdminStates, AssignStates, ServiceStates} from './Enums';
import {time} from './util/util';

const ReceiptPanel = ({details}) => {
  const cn = useStyles();
  const proID = _.find(details.assigns, a => a.state === AssignStates.booked).proID;
  const {move: moveSvc, storage: storageSvc, moveIn: moveInSvc} = details.services;
  const quote = {
    move: details.services.move?.quote || {},
    storage: details.services.storage?.quote || {},
    moveIn: details.services.moveIn?.quote || {},
  };

  const earliestAt = _.map(quote).sort((a,b) => a.at - b.at)[0].at;
  const canCancelOnline = time(earliestAt).isAfter(moment().add(48, 'hours'));
  const canRequestMoveIn = moveInSvc && (
    moveInSvc.state === ServiceStates.reserved || moveInSvc.state === ServiceStates.booked
  )

  const [requestMoveInOpen, setRequestMoveInOpen] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const initMoveInDate = quote.moveIn.at ? time(quote.moveIn.at).format('YYYY-MM-DD') : '';
  const [moveInDate, setMoveInDate] = useState(initMoveInDate)
 
  const handleCancelClick = () => {
    setCancelModalOpen(true);
  };

  const handleCancelConfirm = () => {
    setLoading(true);
    cancelMove()
      .then(() => {
        setCancelModalOpen(false);
        window.location.reload();
      })
      .catch(err => alert(`Failed: ${err.message}`))
      .finally(() => setLoading(false))
  };

  const handleRequestMoveIn = () => {
    setLoading(true);
    requestMoveIn(moveInDate)
      .then(() => {
        setRequestMoveInOpen(false);
        window.location.reload();
      })
      .catch(err => alert(`Failed: ${err.message}`))
      .finally(() => setLoading(false))
  };

  return (
    <div className={cn.panel}>
      <Paper className={cn.root} elevation={2}>
        <div className={cn.panelContent}>
          {/* Reservation Info */}
          <div>
            <div className={cn.sectionHeader}>Your Reservation</div>
            <div className={cn.center}>
              <div>
                <DetailTable data={[["Tracking ID:", details.orderID]]}/>
              </div>
            </div>
            <div className={cn.addresses}>
              <Address title="Pickup" address={details.pickup} />
              <Address title="Dropoff" address={details.destination} />
            </div>
          </div>
          {/* Quote Info */}
          <QuoteInfo details={details} proID={proID} />
          {/* Actions */}
          <div className={cn.sectionBody}>
            <div className={cn.sectionHeader}>Actions</div>
            {/* Request Move In Date */}
            {canRequestMoveIn && <div className={cn.actionRow}>
              <span className={cn.actionLabel}>Ready to move in?</span>
              <Button
                variant="outlined"
                size="large"
                elevation={5}
                className={cn.actionButton}
                onClick={() => setRequestMoveInOpen(true)}>
                {initMoveInDate ? 'Confirm' : 'Request'}
              </Button>
            </div>}
            {/* Made a change */}
            <div className={cn.actionRow}>
              <span className={cn.actionLabel}>Need to make a change?</span>
              <Button
                variant="outlined"
                size="large"
                elevation={5}
                className={cn.actionButton}
                onClick={() => setContactModalOpen(true)}>
                Contact Us
              </Button>
            </div>
            {/* Cancel Action */}
            {/* { false &&
              <div className={cn.actionRow}>
                <span className={cn.actionLabel}>Need to cancel the move?</span>
                <Button
                  variant="outlined"
                  size="large"
                  elevation={5}
                  className={cn.actionButton + ' ' + cn.cancelButton}
                  onClick={handleCancelClick}>
                  Cancel
                </Button>
              </div>
            } */}
            <div className={cn.infoText}>
              {canCancelOnline &&
                <div className={cn.infoLine}>
                  You can cancel your move up to <b>48 hours</b> before your
                  reservation without any additional fees.
                </div>
              }
              {details.state === AdminStates.booked && quote?.move &&
                <div className={cn.infoLine}>
                  Cancellation within the <b>48 hour</b> window before the move,
                  will incur an additional fee of <b>${quote.move.lateCancelFee/100}</b> set by
                  the moving company. To cancel within the 48 hour window please contact us.
                </div>
              }
            </div>
          </div>
        </div>
      </Paper>

      {/* Request Move-In Modal */}
      <Dialog open={requestMoveInOpen}>
        <DialogTitle>
          {initMoveInDate ? 'Confirm' : 'Request'} your move in date.
        </DialogTitle>
        <DialogContent className={cn.dialogContent}>
          <TextField
            id="date"
            className={cn.formLine}
            label="Date"
            InputLabelProps={{shrink: true}}
            type="date"
            placeholder="mm/dd/yyy"
            value={moveInDate}
            onChange={evt => setMoveInDate(evt.target.value)}
            required
          />
        </DialogContent>
        <DialogActions>
          {loading ?
            <CircularProgress />
          : <>
            <Button variant="outlined" onClick={handleRequestMoveIn} disabled={!moveInDate}>Submit</Button>
            <Button onClick={() => setRequestMoveInOpen(false)}>Close</Button>
          </>}
        </DialogActions>
      </Dialog>
      

      {/* Contact Us Modal */}
      <Dialog open={contactModalOpen}>
        <DialogTitle>
          Contact Us
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="error-alert-description">
            If you have any questions or concerns: message us with the app, email us 
            at <a href="mailto:questions@themuva.com">questions@themuva.com</a>, or
            call us at <a href="tel:+17752292149">(775) 229-2149</a>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setContactModalOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Cancel Reservation Modal */}
      <Dialog
        open={cancelModalOpen}>
        <DialogContent className={cn.dialogMessage}>
          <span>Are you sure you want to cancel this reservation?</span>
        </DialogContent>
        <DialogActions className={cn.dialogActions}>
          {loading ?
            <CircularProgress />
          : <>
            <Button variant="outlined" onClick={handleCancelConfirm}>Confirm</Button>
            <Button variant="outlined" onClick={() => setCancelModalOpen(false)}>Close</Button>
          </>}
        </DialogActions>
      </Dialog>
    </div >
  );
};

const useStyles = makeStyles(theme => ({
  panel: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  root: {
    margin: '30px',
    width: '600px',
    '@media only screen and (max-width: 760px)': {
      width: 'calc(100% - 60px)'
    },
  },
  panelContent: {
    padding: '25px'
  },
  sectionHeader: {
    color: "#757575",
    borderBottom: '1px solid #757575',
    marginBottom: '20px',
    fontSize: '25px'
  },
  addresses: {
    display: 'flex',
    color: '#757575',
    justifyContent: 'space-around',
    [theme.breakpoints.down(350)]: {
      flexDirection: 'column',
    },
    marginBottom: 20,
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  sectionBody: {
    marginBottom: '30px',
    marginTop: 30,
  },
  summaryMessage: {
    color: "#757575"
  },
  actionRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '15px',
    '@media only screen and (max-width: 760px)': {
      flexDirection: 'column',
    },
  },
  actionLabel: {
    color: '#757575',
    fontSize: '20px'
  },
  actionButton: {
    //padding: '0',
    width: '150px',
    fontWeight: 'bold',
    '@media only screen and (max-width: 760px)': {
      marginTop: '15px',
    },
  },
  cancelButton: {
    color: '#d60d0d'
  },
  infoText: {
    color: '#757575',
    fontSize: '12px',
    width: '70%',
    '@media only screen and (max-width: 760px)': {
      width: '100%'
    },
  },
  infoLine: {
    marginTop: '15px'
  },
  dialogContent: {
    minWidth: 300,
    paddingBottom: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialogMessage: {
    padding: '35px 30px'
  },
  dialogActions: {
    padding: '12px'
  }
}));

export default ReceiptPanel;