const styles = require('@material-ui/core/styles');

let theme = styles.createTheme({
  palette: {
    primary: { main: '#1F8DCD' },
    secondary: { main: '#f23d7d', dark: '#e04a8e' },
  },
  typography: {
    fontSize: 12,
    useNextVariants: true,
    fontFamily: [
      'Lato',
      'Arial',
      'sans-serif',
    ].join(','),
    fontDisplay: 'swap',
    h1: {
      fontSize: '4.7214285714285714rem',
    },
    // h2: {
    //   fontWeight: 600,
    //   fontSize: '1.2857142857142856rem;',
    //   lineHeight: '1.2',
    //   fontDisplay: 'swap',
    // },
    // h4: {
    //   fontWeight: 600,
    //   fontSize: '1.5214285714285714rem',
    //   lineHeight: '1.2',
    //   fontDisplay: 'swap',
    // },
    // h5: {
    //   fontWeight: 600,
    //   fontDisplay: 'swap',
    // },
    // h6: {
    //   fontWeight: 600,
    //   fontDisplay: 'swap',
    // },
    // subtitle2: {
    //   fontWeight: 600,
    //   fontDisplay: 'swap',
    // },
    // subtitle1: {
    //   lineHeight: '1.5',
    //   fontDisplay: 'swap',
    // },
    body1: {
      fontSize: '0.9571428571428571rem',
    }
  },
});

theme = styles.responsiveFontSizes(theme);

export default theme;
