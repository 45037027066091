import {$tr, PromoDiscount, dateStr, maxPayment, time} from './util/util';
import {TableCell as MuiTableCell, Table, TableBody, TableContainer, TableRow} from '@material-ui/core';
import {makeStyles, useMediaQuery, useTheme, withStyles} from "@material-ui/core";

import React from 'react';
import _ from 'lodash';

const DetailTable = ({data}) => {
  const 
    cn = useTableStyles(),
    theme = useTheme(),
    small = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Table className={cn.table} size="small" aria-label="a dense table">
      <TableBody>
        {data.map(row => 
          <TableRow key={row[0]} className={cn.line}>
            <TableCell>
              <div className={cn.label}>{row[0]}</div>
              {small && <div>{row[1]}</div>}
            </TableCell>
            {!small && <TableCell>{row[1]}</TableCell>}
            <TableCell className={cn.valRight}>{row[2]}</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

const useTableStyles = makeStyles(theme => ({
  table: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: 300,
    },
  },
  valRight: {
    textAlign: 'right',
  },
  line: {
    color: '#757575',
  },
  label: {
    fontWeight: '700',
  },
}));

const TableCell = withStyles({
  root: {
    // borderBottom: "none", 
    color: 'unset',
    fontSize: 'unset',
    padding: '5px',
  },
})(MuiTableCell);

const PaymentInfo = ({details, proID}) => {
  const {appID} = details;
  const isMuvaFreight = appID === 'Muva Freight';
  const descriptionWord = isMuvaFreight ? 'job' : 'move'
  const cn = useStyles();
  const isCustomWebApp = !!details.appID
  if (!proID) return null;

  const {quotes, quotesSummary} = details;
  const quote = quotes[proID];
  const summary = quotesSummary[proID];
  const movePayment = (moveQuote) => !isCustomWebApp ?
    `${$tr(moveQuote.price.min - moveQuote.deposit)} - ${$tr(moveQuote.price.max-moveQuote.deposit)}` :
    `${$tr(moveQuote.price.max)}`

  return (
    <div className={cn.paymentInfoSection}>
      <div className={cn.sectionHeader}>Payments</div>
      <div className={cn.centered}>
        {/* <div className={cn.payments}> */}
          <DetailTable valRight data={[
            summary.deposit && ['Deposit', 'Due today', $tr(summary.deposit)],
            quote.move && 
              [`${!!quote.moveIn ? `${_.capitalize(descriptionWord)} Out` : _.capitalize(descriptionWord)} Payment`, dateStr(time(quote.move.at)), movePayment(quote.move)],
            ...quote.storage ? [
              ['Storage Payment', dateStr(time(quote.storage.at)) , $tr(quote.storage.price.init)],
              ['Storage Extension', 'Monthly if extending',$tr(quote.storage.price.recur)],
            ] : [],
            quote.moveIn && [`${_.capitalize(descriptionWord)} In Payment`, dateStr(time(quote.moveIn.at)), movePayment(quote.moveIn)],
            summary.discountStr && ['Discount', '', summary.discountStr],
          ].filter(d => !!d?.length)}/>
        {/* </div> */}
        <p className={cn.termsText}>
          {!!quote.move && <>
            <b>{isMuvaFreight ? 'Freight': 'Move'} Terms</b>
            <br />
            Your {descriptionWord} payment will be charged to your card after the {descriptionWord}.
            The non-refundable ({$tr(summary.deposit)}) deposit will be credited towards your {descriptionWord} cost.
            <br /><br />
            A {descriptionWord} may be canceled up until forty-eight (48) before the {descriptionWord}, free of charge. A cancellation after this time
            will incur a charge of four (4) hours of the {isMuvaFreight ? 'Freight': 'moving'} companies time, with such hourly cost dependent on the number
            of {isMuvaFreight ? 'team members allocated to the job': 'movers that were allocated for the move'}.
            <br /><br />
            Please note that items not readily viewable in your submitted video, or specifically disclosed in your
            quote application, may incur additional charges.
          </>}
          {!!quote.storage && <>
            <br /><br />
            <b>Storage Terms</b>
            <br/>
            Your storage payment will be charged on the day of storage start. This payment will fully cover storage from {descriptionWord} out to
            the requested {descriptionWord} in. During the term, should an early {descriptionWord} in be necessary, you will receive a prorated refund,
            less 3% Stripe fees. If storage is needed past the requested {descriptionWord} in date, a monthly extension charge will apply at the
            beginning of every month.
          </>}
          <br /><br />
          <b>Service Terms</b>
          <br />
          For each non-recurring payment, hold for the price amount is authorized to your card four (4) days in advance of {descriptionWord}.
          Any funds in excess to the final payment will released upon time of billing. If authorization fails, you have until forty-eight
          (48) hours before the {descriptionWord} to update your payment method, or {descriptionWord} will be automatically canceled.
        </p>
      </div>
    </div>
  )
};

const useStyles = makeStyles((theme) => ({
  paymentInfoSection: {
    marginTop: '10px'
  },
  sectionHeader: {
    color: "#757575",
    paddingBottom: 5,
    borderBottom: '1px solid #757575',
    marginBottom: 5,
    fontSize: '25px'
  },
  // payments: {
  //   maxWidth: 500,
  // },
  termsLabel: {
    color: '#757575',
    fontWeight: '700',
    textAlign: 'center',
  },
  termsText: {
    color: '#757575',
    fontSize: '0.85rem',
    textAlign: 'center',
  },
  centered: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default PaymentInfo;
